<template>
  <el-dialog
    v-loading="loading || addAssociatedProductLoading"
    top="12vh"
    width="30vw"
    visible
    append-to-body
    :before-close="() => $emit('close')"
    custom-class="rounded"
  >
    <template #title>
      <div class="d-flex align-items-center mb-2">
        <HasAssociatedProductsIcon width="16" height="16" :class="$direction === 'ltr' ? 'me-1' : 'ms-1'" />
        <h2>{{ $t('product.productManagementModal.associatedProductsTab.addAssociatedProduct') }}</h2>
      </div>
      <div class="d-flex align-items-center">
        <p>{{ parentProduct.sku }}</p>
        <p class="px-2">•</p>
        <p>{{ parentProduct.name }}</p>
        <p class="px-2">•</p>
        <p>{{ parentProduct.business.name }}</p>
      </div>
    </template>
    <el-form ref="form" :rules="formValidationRules" :model="formModel">
      <div class="d-flex align-items-center">
        <el-form-item
          prop="productId"
          :label="$t('commons.product')"
          label-position="top"
          :class="$direction === 'ltr' ? 'me-2' : 'ms-2'"
          :show-message="false"
        >
          <el-select
            v-model="formModel.productId"
            filterable
            :placeholder="$t('commons.select')"
            placement="bottom-end"
          >
            <el-option-group v-for="group in selectGroups" :key="group.label">
              <small class="fw-bold mx-4 font-size-small">{{ group.label }}</small>
              <el-option
                v-for="option in group.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
                <div class="text-typography-primary">
                  {{ option.label }}
                </div>
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="quantity"
          :label="$t('product.productManagementModal.associatedProductsTab.associatedQuantity')"
          label-position="top"
          :class="$direction === 'ltr' ? 'me-2' : 'ms-2 quantity-input'"
          :show-message="false"
        >
          <el-input v-model="formModel.quantity" />
        </el-form-item>
        <el-form-item
          prop="partOf"
          label-position="top"
          :label="$t('product.productManagementModal.associatedProductsTab.column')"
          :show-message="false"
        >
          <el-select v-model="formModel.partOf" :placeholder="$t('commons.select')" placement="bottom-end">
            <el-option
              v-for="(value, key) in ASSOCIATED_PRODUCT_DOCUMENT_COLUMNS"
              :key="key"
              :label="$t(`product.productManagementModal.associatedProductsTab.partOf.${key}`)"
              :value="value"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item prop="integralToParent">
        <div class="d-flex align-items-center">
          <el-radio-group v-model="formModel.integralToParent">
            <p class="text-typography-primary mb-2 fw-bold">
              {{ $t('product.productManagementModal.associatedProductsTab.isIncludedAsPartOfOrderPrice') }}
            </p>
            <el-radio label="included" class="m-0 text-typography-primary">{{
              $t('product.productManagementModal.associatedProductsTab.included')
            }}</el-radio>
            <el-radio label="notIncluded" class="text-typography-primary">{{
              $t('product.productManagementModal.associatedProductsTab.notIncluded')
            }}</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
    <div class="d-flex justify-content-end">
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" @click="handleApproveButton">{{ $t('commons.apply') }}</Button>
    </div>
  </el-dialog>
</template>

<script>
import { computed, reactive, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { flatten, uniq, reject, isNil } from 'ramda';

import { Button } from '@/modules/core/components';
import { HasAssociatedProductsIcon } from '@/assets/icons';

import { useProductsNew } from '../../../compositions/products';
import { ADD_ASSOCIATED_PRODUCT_MUTATION } from '../compositions/graphql';

const ASSOCIATED_PRODUCT_DOCUMENT_COLUMNS = {
  kegCost: 'totalPackages',
  tax: 'totalTax',
  deposit: 'totalDeposits',
};

export default {
  components: { HasAssociatedProductsIcon, Button },
  props: {
    parentProduct: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const formModel = reactive({
      productId: null,
      quantity: null,
      integralToParent: null,
      partOf: null,
    });

    const { products, loading } = useProductsNew(computed(() => props.parentProduct.business.id));

    const {
      mutate: addAssociatedProductMutation,
      loading: addAssociatedProductLoading,
      onDone: addAssociatedProductOnDone,
      onError: addAssociatedProductOnError,
    } = useMutation(ADD_ASSOCIATED_PRODUCT_MUTATION);

    addAssociatedProductOnDone(() => {
      root.$message.success(
        root.$i18n.t('product.productManagementModal.associatedProductsTab.associatedProductAddSuccessMessage')
      );
      emit('associated-product-added');
    });

    addAssociatedProductOnError(() =>
      root.$message.error(
        root.$i18n.t('product.productManagementModal.associatedProductsTab.associatedProductAddErrorMessage')
      )
    );

    const formValidationRules = {
      productId: [{ type: 'string', required: true }],
      quantity: [
        {
          required: true,
        },
        {
          validator: (_, value, callback) => {
            const isQuantityFloatNumber = value.toString().match(/[-+]?[0-9]*\.?[0-9]*/)[0] === value.toString();
            isQuantityFloatNumber ? callback() : callback(new Error('quantity must be a float number'));
          },
          trigger: ['change', 'blur'],
        },
      ],
      integralToParent: [
        {
          type: 'string',
          required: true,
          message: root.$t('product.productManagementModal.associatedProductsTab.validationMessage'),
        },
      ],
    };

    return {
      ASSOCIATED_PRODUCT_DOCUMENT_COLUMNS,
      formModel,
      formValidationRules,
      supplierProducts: computed(() => products.value.filter((product) => product.id !== props.parentProduct.id)),
      loading,
      addAssociatedProductMutation,
      addAssociatedProductLoading,
    };
  },
  computed: {
    associatedProductIds() {
      return uniq(
        flatten(
          this.supplierProducts
            .filter((supplierProduct) => supplierProduct.associatedProducts.length)
            .map((supplierProduct) => supplierProduct.associatedProducts)
        ).map((associatedProduct) => associatedProduct.productId)
      );
    },
    associatedProducts() {
      return this.supplierProducts.filter((supplierProduct) => this.associatedProductIds.includes(supplierProduct.id));
    },
    mainProducts() {
      return this.supplierProducts.filter((supplierProduct) => !this.associatedProductIds.includes(supplierProduct.id));
    },
    selectGroups() {
      return [
        {
          label: this.$t('product.productManagementModal.associatedProducts'),
          options: this.getProductOptions(this.associatedProducts),
        },
        {
          label: this.$t('product.productManagementModal.associatedProductsTab.additionalProducts'),
          options: this.getProductOptions(this.mainProducts),
        },
      ];
    },
  },
  methods: {
    handleApproveButton() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addAssociatedProductMutation({
            parentProductId: this.parentProduct.id,
            params: {
              ...reject(isNil)(this.formModel),
              quantity: Number.parseFloat(this.formModel.quantity),
              integralToParent: this.formModel.integralToParent === 'included',
            },
          });
        }
      });
    },
    getProductOptions(products) {
      return products.map((product) => ({
        label: product.sku ? `${product.sku} - ${product.name}` : product.name,
        value: product.id,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quantity-input {
  input {
    direction: ltr;
    text-align: end;
  }
}
</style>
