var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.deleteAssociatedProductLoading),expression:"deleteAssociatedProductLoading"}]},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('product.productManagementModal.associatedProducts')))]),_c('Button',{staticClass:"text-primary p-0",attrs:{"type":"text"},on:{"click":function($event){_vm.isAddModalOpen = true}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('PlusIcon',{class:_vm.$direction === 'ltr' ? 'me-2' : 'ms-2',attrs:{"width":"24","height":"24"}}),_c('p',[_vm._v(_vm._s(_vm.$t('product.productManagementModal.associatedProductsTab.associatedProduct')))])],1)])],1),_c('Table',{staticClass:"mb-4",attrs:{"data":_vm.tableData,"columns":_vm.columns,"show-index":"","border":"","rounded":""},scopedSlots:_vm._u([{key:"cell-sku",fn:function({
        rowData: {
          product: { sku },
        },
      }){return [_vm._v(_vm._s(sku))]}},{key:"cell-name",fn:function({
        rowData: {
          product: { name },
        },
      }){return [_vm._v(_vm._s(name))]}},{key:"cell-integralToParent",fn:function({ rowData: { integralToParent } }){return [(integralToParent)?[_vm._v(_vm._s(_vm.$t('product.productManagementModal.associatedProductsTab.included')))]:[_vm._v("-")]]}},{key:"cell-documentColumn",fn:function({ rowData: { partOf } }){return [(!_vm.isNil(partOf))?[_vm._v(" "+_vm._s(_vm.$t(`product.productManagementModal.associatedProductsTab.partOf.${_vm.DOCUMENT_COLUMN_TRANSLATION[partOf]}`))+" ")]:[_vm._v("-")]]}},{key:"cell-options",fn:function({ rowData: { productId } }){return [_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom"},on:{"command":(method) => _vm._self[method](productId)}},[_c('Button',{staticClass:"p-0 text-typography-primary",attrs:{"type":"text"}},[_c('KebabIcon',{attrs:{"width":"24","height":"24"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"handleAssociatedProductDelete"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('TrashCanIcon',{staticClass:"text-danger",class:_vm.$direction === 'ltr' ? 'me-2' : 'ms-2',attrs:{"width":"24","height":"24"}}),_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('product.productManagementModal.associatedProductsTab.deleteAssociatedProduct'))+" ")])],1)])],1)],1)]}}])}),(_vm.isAddModalOpen)?_c('AddAssociatedProductModal',{attrs:{"parent-product":_vm.parentProduct},on:{"associated-product-added":_vm.handleAssociatedProductAdd,"close":function($event){_vm.isAddModalOpen = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }