<template>
  <div v-loading="deleteAssociatedProductLoading">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h3>{{ $t('product.productManagementModal.associatedProducts') }}</h3>
      <Button type="text" class="text-primary p-0" @click="isAddModalOpen = true">
        <div class="d-flex align-items-center">
          <PlusIcon width="24" height="24" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'" />
          <p>{{ $t('product.productManagementModal.associatedProductsTab.associatedProduct') }}</p>
        </div>
      </Button>
    </div>
    <Table :data="tableData" :columns="columns" show-index border rounded class="mb-4">
      <template
        #cell-sku="{
          rowData: {
            product: { sku },
          },
        }"
        >{{ sku }}</template
      >
      <template
        #cell-name="{
          rowData: {
            product: { name },
          },
        }"
        >{{ name }}</template
      >
      <template #cell-integralToParent="{ rowData: { integralToParent } }">
        <template v-if="integralToParent">{{
          $t('product.productManagementModal.associatedProductsTab.included')
        }}</template>
        <template v-else>-</template>
      </template>
      <template #cell-documentColumn="{ rowData: { partOf } }">
        <template v-if="!isNil(partOf)">
          {{ $t(`product.productManagementModal.associatedProductsTab.partOf.${DOCUMENT_COLUMN_TRANSLATION[partOf]}`) }}
        </template>
        <template v-else>-</template>
      </template>
      <template #cell-options="{ rowData: { productId } }">
        <el-dropdown trigger="click" placement="bottom" @command="(method) => _self[method](productId)">
          <Button type="text" class="p-0 text-typography-primary">
            <KebabIcon width="24" height="24" />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="handleAssociatedProductDelete">
              <div class="d-flex align-items-center">
                <TrashCanIcon
                  width="24"
                  height="24"
                  class="text-danger"
                  :class="$direction === 'ltr' ? 'me-2' : 'ms-2'"
                />
                <p class="text-danger">
                  {{ $t('product.productManagementModal.associatedProductsTab.deleteAssociatedProduct') }}
                </p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AddAssociatedProductModal
      v-if="isAddModalOpen"
      :parent-product="parentProduct"
      @associated-product-added="handleAssociatedProductAdd"
      @close="isAddModalOpen = false"
    />
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { isNil } from 'ramda';

import { PlusIcon, KebabIcon, TrashCanIcon } from '@/assets/icons';
import { Button, Table } from '@/modules/core/components';

import AddAssociatedProductModal from './components/AddAssociatedProductModal';
import { DELETE_ASSOCIATED_PRODUCT_MUTATION } from './compositions/graphql';

const TABLE_HEADERS = {
  SKU: 'sku',
  NAME: 'name',
  QUANTITY: 'quantity',
  INTEGRAL_TO_PARENT: 'integralToParent',
  DOCUMENT_COLUMN: 'documentColumn',
  OPTIONS: 'options',
};

const DOCUMENT_COLUMN_TRANSLATION = {
  totalPackages: 'kegCost',
  totalTax: 'tax',
  totalDeposits: 'deposit',
};

export default {
  components: { AddAssociatedProductModal, PlusIcon, Button, Table, KebabIcon, TrashCanIcon },
  props: {
    parentProduct: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const productsToAdd = ref([]);
    const tableData = computed(() => props.parentProduct.associatedProducts);

    const {
      mutate: deleteAssociatedProductMutation,
      loading: deleteAssociatedProductLoading,
      onDone: deleteAssociatedProductOnDone,
      onError: deleteAssociatedProductOnError,
    } = useMutation(DELETE_ASSOCIATED_PRODUCT_MUTATION);

    deleteAssociatedProductOnDone(() => {
      root.$message.success(
        root.$i18n.t('product.productManagementModal.associatedProductsTab.associatedProductDeleteSuccessMessage')
      );
      emit('refetch');
    });

    deleteAssociatedProductOnError(() =>
      root.$message.error(
        root.$i18n.t('product.productManagementModal.associatedProductsTab.associatedProductDeleteErrorMessage')
      )
    );

    return {
      isNil,
      DOCUMENT_COLUMN_TRANSLATION,
      tableData,
      productsToAdd,
      deleteAssociatedProductMutation,
      deleteAssociatedProductLoading,
      isAddModalOpen: ref(false),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.sku'),
          key: TABLE_HEADERS.SKU,
          width: '10%',
        },
        {
          header: this.$t('commons.name'),
          key: TABLE_HEADERS.NAME,
          width: '26%',
        },
        {
          header: this.$t('product.productManagementModal.associatedProductsTab.associatedQuantity'),
          key: TABLE_HEADERS.QUANTITY,
          width: '15%',
        },
        {
          header: this.$t('product.productManagementModal.associatedProductsTab.orderPrice'),
          key: TABLE_HEADERS.INTEGRAL_TO_PARENT,
          width: '16%',
        },
        {
          header: this.$t('product.productManagementModal.associatedProductsTab.documentColumn'),
          key: TABLE_HEADERS.DOCUMENT_COLUMN,
          width: '18%',
        },
        {
          header: '',
          key: TABLE_HEADERS.OPTIONS,
        },
      ];
    },
  },
  methods: {
    handleAssociatedProductAdd() {
      this.$emit('refetch');
      this.isAddModalOpen = false;
    },
    handleAssociatedProductDelete(productIdToDelete) {
      this.deleteAssociatedProductMutation({
        parentProductId: this.parentProduct.id,
        associatedProductId: productIdToDelete,
      });
    },
  },
};
</script>
