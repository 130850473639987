<template>
  <el-select v-bind="$attrs" :value="selectedValue" popper-class="actionable-select" @change="onSelect">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
    >
    </el-option>
    <el-option value="new">
      <div>
        <p class="divider"></p>
        <slot />
      </div>
    </el-option>
  </el-select>
</template>
<script>
export default {
  name: 'ActionableSelect',
  props: { options: { type: Array, default: () => [] }, selectedValue: { type: String, default: null } },
  emits: ['on-select', 'on-action'],
  setup(_, { emit }) {
    const onSelect = (newSelectedValue) => {
      if (newSelectedValue === 'new') {
        emit('on-action');
      } else {
        emit('on-select', newSelectedValue);
      }
    };

    return {
      onSelect,
    };
  },
};
</script>
<style lang="scss" scoped>
.divider {
  height: 1px;
  background: #e3e4e6;
}
</style>
<style lang="scss">
.actionable-select {
  .el-select-dropdown__item.selected {
    color: #1f284d !important;
  }
}
</style>
