import { gql } from '@apollo/client/core';

export const ADD_ASSOCIATED_PRODUCT_MUTATION = gql`
  mutation productAddAssociatedProduct($parentProductId: ID!, $params: AssociatedProductCreateParams!) {
    productAddAssociatedProduct(parentProductId: $parentProductId, params: $params) {
      productId
      quantity
      integralToParent
    }
  }
`;

export const DELETE_ASSOCIATED_PRODUCT_MUTATION = gql`
  mutation productDeleteAssociatedProduct($parentProductId: ID!, $associatedProductId: ID!) {
    productDeleteAssociatedProduct(parentProductId: $parentProductId, associatedProductId: $associatedProductId)
  }
`;
